require('../css/WingmanTheme/scss/theme.scss');
require('../css/entypo.css');
// require('../css/socicon.css');
require('../js/bootstrap/index');
require('../css/WingmanTheme/dist/assets/js/theme');
// require('../css/WingmanTheme/dist/assets/js/jquery.smartWizard.min');
require('./app_demission');
require('./carte2019');
/*require('./smarttag');
require('./atinternet');
require("cookieconsent/build/cookieconsent.min.css")
require("cookieconsent")
require('./cookies');*/
require('jarallax/dist/jarallax.css');
require('./mrare/index');



const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);
