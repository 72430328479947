import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';


let bulleMots = document.querySelectorAll('#bullemot');
populateMot(bulleMots)

let etapes_url = {1: 'etape1_remplir-les-conditions', 2: 'etape2_elaborer_son_projet', 3: 'etape3_valider_son_projet',4:'etape4_s_inscrire_a_pole_emploi'};
function populateMot(bulleMots) {
    for (let i = 0; i < bulleMots.length; i++) {
        let button = bulleMots[i];
        bulleMots[i].innerHTML = "";
        button.textContent = bulleMots[i].dataset.mot;
        button.setAttribute('id', "t_mot" + bulleMots[i].dataset.num);
        button.classList.add("rose", "titre0");
        // bulleMots[i].appendChild(button);
    }
}


let etapes1_titre = document.querySelectorAll('#etapes1_titre');
for (let i = 0; i < etapes1_titre.length; i++) {
    etapes1_titre[i].addEventListener('click', function () {
        let step = etapes1_titre[i].dataset.step;
        // on affiche le menu principale si on clique sur le 1er item (item0)
        window.open('/etape1_' + step, '_self');
    })
}

/*function affiche_tippy(element) {
    const variable = document.getElementById(element);
    const button = document.querySelector('button');
    if (variable !== null) {
        tippy('#t_' + element, {
            allowHTML: true,
            maxWidth: "none",
            theme: 'sycorax',
            content: variable.innerHTML,
            interactive: false,
             placement: 'auto',
             zIndex: 9999,
        });
    }
}

for (let i = 1; i < 17; i++) {
    affiche_tippy('mot' + i);
}*/

function steps(step) {
    var steps = document.querySelectorAll('#step' + step);
    for (let i = 0; i < steps.length; i++) {
        steps[i].addEventListener('click', function () {
            window.open(etapes_url[step], '_self');
        })
    }
}

steps(1)
steps(2)
steps(3)
steps(4)
