var body = document.querySelector('body');
var france2019 = document.querySelector('#france2019');
var g = document.querySelectorAll('#france2019 g');
var paths = document.querySelectorAll('#france2019 g path');
var region = document.querySelector('#regionName');
var width = document.documentElement.clientWidth;
var height = document.documentElement.clientHeight;
var regionArray = [];

if (france2019 && width >= 500) {
    for (let i = 0; i < paths.length; i++) {
        paths[i].addEventListener('mouseover', function () {
            region.style.opacity = "1";
            for (let i = 0; i < paths.length; i++) {
                paths[i].style.opacity = "0.4";
            }
            this.style.opacity = "1";
            region.innerHTML = paths[i].dataset.region.toUpperCase();
            // console.log(paths[i].dataset.link)
            region.setAttribute('href', 'https://www.pole-emploi.fr/accueil/' + i)
            france2019.onmousemove = function (e) {
                var x = e.clientX;
                var y = e.clientY;
                region.style.left = (x + 20) + "px";
                region.style.top = (y - 80) + "px";
            }
        });
        paths[i].addEventListener('mouseout', function () {
            for (let i = 0; i < paths.length; i++) {
                paths[i].style.opacity = "";
            }
            region.style.opacity = "0";
        });
    }

}
/*
for (let i = 0; i < paths.length; i++) {
    paths[i].addEventListener('click', function() {
        // document.location.href = g[i].dataset.link;
        window.open(paths[i].dataset.link, '_blank');
    });
}*/
